import { combineReducers } from "redux";
import aclList from "./aclList";
import setupRole from "./setupRole";
import removeRole from "./removeRole";

const aclReducer = combineReducers({
  aclList,
  setupRole,
  removeRole,
});
export default aclReducer;
