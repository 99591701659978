import React from "react";
import * as Layout from "../layouts";

const SignIn = React.lazy(() => import("../containers/signIn"));
const Home = React.lazy(() => import("../containers/home"));
const Profile = React.lazy(() => import("../containers/profile"));
const Apps = React.lazy(() => import("../containers/apps"));
const Users = React.lazy(() => import("../containers/users"));
const Histories = React.lazy(() => import("../containers/histories"));
const Page404 = React.lazy(() => import("../components/common/Page404"));
const RedirectApp = React.lazy(() => import("../containers/RedirectApp"));

const routes = [
  {
    layout: Layout.SignIn,
    component: SignIn,
    path: "/",
    exact: true,
    isPrivate: false,
  },
  {
    layout: Layout.SignIn,
    component: SignIn,
    path: "/login",
    exact: true,
    isPrivate: false,
  },
  {
    layout: Layout.Default,
    component: Profile,
    path: "/user/profile",
    exact: true,
    isPrivate: true,
  },
  {
    layout: Layout.Default,
    component: Home,
    path: "/home",
    exact: true,
    isPrivate: true,
  },
  {
    layout: Layout.Default,
    component: Apps,
    path: "/apps",
    exact: true,
    isPrivate: true,
  },
  {
    layout: Layout.Default,
    component: Users,
    path: "/users",
    exact: true,
    isPrivate: true,
  },
  {
    layout: Layout.Default,
    component: Histories,
    path: "/history",
    exact: true,
    isPrivate: true,
  },
  {
    layout: Layout.SignIn,
    component: RedirectApp,
    path: "/ra",
    exact: true,
    isPrivate: false,
  },
  {
    layout: Layout.SignIn,
    component: Page404,
    private: false,
  },
];

export default routes;
