import { call, put, takeLatest } from "@redux-saga/core/effects";
import apiCaller from "api/lib/apiPortalCaller";
import { SUCCESS_CODE } from "configs/constants";
import * as types from "redux/portal.types";
import { customApiError } from "utils/helpers/customAPIError";

const { getAccessList, editAccess, removeAccess } = apiCaller;

function* getACLSaga({ payload }) {
  try {
    const { code, data } = yield call(getAccessList, payload.data);

    if (!SUCCESS_CODE.includes(code)) {
      throw customApiError(code, data.message);
    }

    yield put({
      type: types.GET_ACL_SUCCESS,
      payload: {
        code,
        data: data.items,
        total: data.total,
      },
    });
  } catch (error) {
    yield put({ type: types.GET_ACL_FAIL, payload: error });
  } finally {
    yield put({ type: types.GET_ACL_RF });
  }
}

function* setupACLSaga({ payload }) {
  try {
    const { code, data } = yield call(editAccess, payload.data);

    if (!SUCCESS_CODE.includes(code)) {
      throw customApiError(code, data.message);
    }

    yield put({
      type: types.SETUP_ACL_SUCCESS,
      payload: {
        token: data.accessToken,
        code,
        messages: data.message,
      },
    });
  } catch (error) {
    yield put({
      type: types.SETUP_ACL_FAIL,
      payload: error,
    });
  } finally {
    yield put({
      type: types.SETUP_ACL_RF,
    });
  }
}

function* removeACLSaga({ payload }) {
  try {
    const { code, data } = yield call(removeAccess, payload.data);

    if (!SUCCESS_CODE.includes(code)) {
      throw customApiError(code, data.message);
    }

    yield put({
      type: types.REMOVE_ACL_SUCCESS,
      payload: {
        token: data.accessToken,
        code,
        messages: data.message,
      },
    });
  } catch (error) {
    yield put({
      type: types.REMOVE_ACL_FAIL,
      payload: error,
    });
  } finally {
    yield put({
      type: types.REMOVE_ACL_RF,
    });
  }
}

export default function* watchAction() {
  yield takeLatest(types.GET_ACL_RQ, getACLSaga);
  yield takeLatest(types.SETUP_ACL_RQ, setupACLSaga);
  yield takeLatest(types.REMOVE_ACL_RQ, removeACLSaga);
}
