import { configAPI } from "../../configs/api.config";
import Request from "../middleware/request";

const apiPortalCaller = {
  // login
  login(args) {
    return apiPortalCaller.callAPI("post", "/v1/account/login", {
      ...args,
    });
  },
  // login
  logout(args) {
    return apiPortalCaller.callAPI("post", "/v1/account/logout", {
      ...args,
    });
  },

  // lấy thông tin user portal
  getUserDetail(args) {
    return apiPortalCaller.callAPI("get", "/v1/account", {
      ...args,
    });
  },

  // cập nhật user portal
  updateUser({ ...args }) {
    return apiPortalCaller.callAPI("put", `/v1/account`, { ...args });
  },

  //thay đổi password portal
  changePassword({ ...args }) {
    return apiPortalCaller.callAPI("post", `/v1/account/change_password`, {
      ...args,
    });
  },

  //thêm mật khẩu cho user social portal
  addPassword({ ...args }) {
    return apiPortalCaller.callAPI("post", `/v1/account/set_password`, {
      ...args,
    });
  },

  //cài đặt Google Auth portal
  setGoogleAuth({ ...args }) {
    return apiPortalCaller.callAPI(
      "post",
      `/v1/google/authenticator/createQR`,
      { ...args }
    );
  },

  // đăng kí/huỷ sd chứng thực 2 bước, update verify
  updateChannelVerify({ ...args }) {
    return apiPortalCaller.callAPI(
      "post",
      `/v1/account/2-step-verification/register`,
      { ...args }
    );
  },

  // đăng kí/huỷ thông báo, update notify
  updateChannelNotify({ ...args }) {
    return apiPortalCaller.callAPI(
      "post",
      `/v1/account/notification/register`,
      { ...args }
    );
  },

  //liên kết Telegram
  linkTelegram({ ...args }) {
    return apiPortalCaller.callAPI("post", `/v1/telegram/link`, {
      ...args,
    });
  },

  //huỷ liên kết Telegram
  unlinkTelegram({ ...args }) {
    return apiPortalCaller.callAPI("post", `/v1/telegram/unlink`, {
      ...args,
    });
  },

  //huỷ liên kết Google
  unlinkGoogle({ ...args }) {
    return apiPortalCaller.callAPI("post", `/v1/google/unlink`, {
      ...args,
    });
  },

  //lấy danh sách các ứng dụng
  getListApp({ ...args }) {
    return apiPortalCaller.callAPI("post", `/v1/application/list`, {
      ...args,
    });
  },

  // thêm mới app
  addApp({ ...args }) {
    return apiPortalCaller.callAPI("post", `/v1/application`, {
      ...args,
    });
  },
  // xoá app
  removeApp({ ...args }) {
    return apiPortalCaller.callAPI("delete", `/v1/application`, {
      ...args,
    });
  },
  // chỉnh sửa app
  editApp({ id, ...args }) {
    return apiPortalCaller.callAPI("put", `/v1/application/${id}`, {
      ...args,
    });
  },

  // thêm mới user
  addUser({ ...args }) {
    return apiPortalCaller.callAPI("post", `/v1/account`, { ...args });
  },

  // lấy danh sách user
  getUserList({ ...args }) {
    return apiPortalCaller.callAPI("post", `/v1/account/list`, {
      ...args,
    });
  },

  // xoá user
  removeUser({ ...args }) {
    return apiPortalCaller.callAPI("delete", `/v1/account`, {
      ...args,
    });
  },

  // chỉnh sửa User
  editUser({ accountId, ...args }) {
    return apiPortalCaller.callAPI("put", `/v1/account/${accountId}`, {
      ...args,
    });
  },

  // lấy danh sách history
  getHistoryList({ ...args }) {
    return apiPortalCaller.callAPI("post", `/v1/history`, { ...args });
  },

  // quên mật khẩu
  forgotPassword({ ...args }) {
    return apiPortalCaller.callAPI("post", `/v1/account/forgot_password`, {
      ...args,
    });
  },

  //gửi OTP quên mật khẩu
  sendOTPForgotPassword({ ...args }) {
    return apiPortalCaller.callAPI("post", `/v1/account/otp_verification`, {
      ...args,
    });
  },

  //reset mật khẩu
  resetPassword({ ...args }) {
    return apiPortalCaller.callAPI("post", `/v1/account/reset_password`, {
      ...args,
    });
  },

  //lấy danh sách cấp quyền user
  getAccessList({ ...args }) {
    return apiPortalCaller.callAPI("post", `/v1/acl/list`, {
      ...args,
    });
  },

  // chỉnh sửa quyền user truy cập app
  editAccess({ ...args }) {
    return apiPortalCaller.callAPI("post", `/v1/acl/setup_role`, {
      ...args,
    });
  },

  // xoá quyền user truy cập app
  removeAccess({ ...args }) {
    return apiPortalCaller.callAPI("delete", `/v1/acl/setup_role`, {
      ...args,
    });
  },

  uploadImage(args, headers) {
    return apiPortalCaller.callUploadAPI("post", "/Upload", args, headers);
  },

  callAPI(method, pathUrl, body, headers = {}) {
    return Request.callAPI(
      method,
      configAPI.portal.url,
      pathUrl,
      body,
      headers
    );
  },
  callUploadAPI(method, pathUrl, body, headers = {}) {
    return Request.callAPI(
      method,
      configAPI.portal.urlUpload,
      pathUrl,
      body,
      headers
    );
  },
};

export default apiPortalCaller;
