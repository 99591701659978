import React from "react";
import Content from "../default/Content";

function SignIn(props) {
  return (
    <div className="container-master">
      <Content {...props} />
    </div>
  );
}

export default SignIn;
