import * as types from "../portal.types";

const initState = {
  loading: false,
  error: false,
  code: undefined,
  accessToken: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initState, { type, payload }) {
  switch (type) {
    case types.SIGNIN_RQ:
      return {
        ...state,
        loading: true,
      };
    case types.SIGNIN_SUCCESS:
      return {
        ...state,
        loading: false,
        code: payload.code,
        accessToken: payload.token,
      };
    case types.SIGNIN_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        code: payload.code,
      };
    case types.SIGNIN_RF:
      return {
        ...state,
        loading: false,
        error: false,
        code: undefined,
      };

    case types.LOGOUT_RQ:
      return {
        ...state,
        loading: true,
      };
    case types.LOGOUT_SUCCESS_:
      return initState;
    case types.LOGOUT_FAIL_:
      return {
        ...state,
        loading: false,
        error: payload,
        code: payload.code,
      };
    default:
      return state;
  }
}
