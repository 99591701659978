/* eslint-disable import/no-anonymous-default-export */
import * as types from "../portal.types";

const initState = {
  loading: false,
  error: false,
  code: undefined,
  messages: "",
};

export default function (state = initState, { type, payload }) {
  switch (type) {
    case types.SETUP_ACL_RQ:
      return {
        ...state,
        loading: true,
      };
    case types.SETUP_ACL_SUCCESS:
      return {
        ...state,
        loading: false,
        code: payload.code,
        messages: payload.messages,
      };
    case types.SETUP_ACL_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        code: payload.code,
        messages: payload.message,
      };
    case types.SETUP_ACL_RF:
      return {
        ...state,
        loading: false,
        error: false,
        code: undefined,
      };

    default:
      return state;
  }
}
