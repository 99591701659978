import * as types from "./portal.types";

// sign in by Minh
export const signInAction = (data) => ({
  type: types.SIGNIN_RQ,
  payload: { data },
});

export const checkAuthorizeAction = () => ({
  type: types.CHECK_AUTHORIZE_RQ,
});

export const logoutAction = (data) => ({
  type: types.LOGOUT_RQ,
  payload: { data },
});

export const getProfile = (data) => ({
  type: types.GET_PROFILE_RQ,
  payload: {
    data,
  },
});

export const updateProfile = (data) => ({
  type: types.UPDATE_PROFILE_RQ,
  payload: {
    data,
  },
});

export const getAppsAction = (data) => ({
  type: types.GET_APPS_RQ,
  payload: {
    data,
  },
});

export const createAppsAction = (data) => ({
  type: types.CREATE_APP_RQ,
  payload: {
    data,
  },
});

export const updateAppsAction = (data) => ({
  type: types.UPDATE_APP_RQ,
  payload: {
    data,
  },
});

export const deleteAppsAction = (data) => ({
  type: types.DELETE_APP_RQ,
  payload: {
    data,
  },
});

export const getUsersAction = (data) => ({
  type: types.GET_USERS_RQ,
  payload: {
    data,
  },
});

export const createUserAction = (data) => ({
  type: types.CREATE_USER_RQ,
  payload: {
    data,
  },
});

export const updateUserAction = (data) => ({
  type: types.UPDATE_USER_RQ,
  payload: {
    data,
  },
});

export const deleteUserAction = (data) => ({
  type: types.DELETE_USER_RQ,
  payload: {
    data,
  },
});

export const getHistoriesAction = (data) => ({
  type: types.GET_HISTORIES_RQ,
  payload: {
    data,
  },
});

export const getACLAction = (data) => ({
  type: types.GET_ACL_RQ,
  payload: {
    data,
  },
});

export const setupACLAction = (data) => ({
  type: types.SETUP_ACL_RQ,
  payload: {
    data,
  },
});

export const removeACLAction = (data) => ({
  type: types.REMOVE_ACL_RQ,
  payload: {
    data,
  },
});
