import { combineReducers } from "redux";
import userList from "./userList";
import created from "./userCreated";
import updated from "./userUpdated";
import deleted from "./userDeleted";

const users = combineReducers({
  userList,
  created,
  updated,
  deleted,
});

export default users;
