import { call, put, takeLatest } from "@redux-saga/core/effects";
import apiCaller from "api/lib/apiPortalCaller";
import { SUCCESS_CODE } from "configs/constants";
import * as types from "redux/portal.types";
import { customApiError } from "utils/helpers/customAPIError";
import history from "../history";

const { login, getUserDetail, logout, updateUser } = apiCaller;

function* logoutHandler() {
  yield localStorage.removeItem("token");
  yield history.push("/login");
}

export function* signInSaga({ payload }) {
  try {
    const { code, data } = yield call(login, payload.data);

    if (!SUCCESS_CODE.includes(code)) {
      throw customApiError(code, data.message);
    }

    if (code === 1000) {
      yield localStorage.setItem("token", data.accessToken);
    }
    yield put({
      type: types.SIGNIN_SUCCESS,
      payload: {
        token: data.accessToken,
        code,
      },
    });
  } catch (error) {
    yield put({
      type: types.SIGNIN_FAIL,
      payload: error,
    });
  } finally {
    yield put({
      type: types.SIGNIN_RF,
    });
  }
}

export function* getProfileSaga({ payload }) {
  try {
    const { code, data } = yield call(getUserDetail, payload.data);
    if (!SUCCESS_CODE.includes(code)) {
      throw customApiError(code, data.message);
    }
    yield put({
      type: types.GET_PROFILE_SUCCESS,
      payload: {
        code,
        profile: data,
      },
    });
  } catch (error) {
    yield put({ type: types.GET_PROFILE_FAIL, payload: error });
    if (error.code === 401) {
      yield logoutHandler();
    }
  } finally {
    yield put({ type: types.GET_PROFILE_RF });
  }
}

export function* updatedProfileSaga({ payload }) {
  try {
    const { code, data } = yield call(updateUser, payload.data);

    if (!SUCCESS_CODE.includes(code)) {
      throw customApiError(code, data.message);
    }
    const userProfile = yield call(getUserDetail, {
      accessToken: localStorage.getItem("token"),
    });

    if (!SUCCESS_CODE.includes(userProfile.code)) {
      throw customApiError(userProfile.code, userProfile.data.message);
    }

    yield put({
      type: types.UPDATE_PROFILE_SUCCESS,
      payload: {
        code,
        profile: userProfile.data,
        messages: data.message,
      },
    });
  } catch (error) {
    yield put({ type: types.UPDATE_PROFILE_FAIL, payload: error });
  } finally {
    yield put({ type: types.UPDATE_PROFILE_RF });
  }
}

export function* logoutSaga({ payload }) {
  try {
    const { code, data } = yield call(logout, payload.data);

    if (!SUCCESS_CODE.includes(code)) {
      throw customApiError(code, data.message);
    }

    yield put({
      type: types.LOGOUT_SUCCESS_,
    });
    yield logoutHandler();
  } catch (error) {
    yield put({
      type: types.LOGOUT_FAIL_,
      payload: error,
    });
  }
}

export function* checkAuthorizationSaga() {
  try {
    const token = yield localStorage.getItem("token");
    if (token !== "undefined" && token) {
      yield put({
        type: types.SIGNIN_SUCCESS,
        payload: {
          token,
          code: 1000,
        },
      });
    } else {
      throw customApiError({ code: -1001 });
    }
  } catch (error) {
    yield put({
      type: types.LOGOUT_SUCCESS_,
    });
    yield logoutHandler();
  }
}

export default function* watchUserAuthentication() {
  yield takeLatest(types.SIGNIN_RQ, signInSaga);
  yield takeLatest(types.LOGOUT_RQ, logoutSaga);
  yield takeLatest(types.GET_PROFILE_RQ, getProfileSaga);
  yield takeLatest(types.UPDATE_PROFILE_RQ, updatedProfileSaga);
  yield takeLatest(types.CHECK_AUTHORIZE_RQ, checkAuthorizationSaga);
}
