import React, { useEffect } from "react";
import Content from "./Content";
import Header from "./Header";
import { useDispatch } from "react-redux";
import { checkAuthorizeAction } from "redux/portal.actions";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ isHeader = true, isFooter = true, ...rest }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(checkAuthorizeAction());
  }, [dispatch]);

  return (
    <div className="container-master">
      {isHeader && <Header />}
      <Content {...rest} />
      <ToastContainer />
    </div>
  );
}
