import { combineReducers } from "redux";
import { i18nState } from "redux-i18n";
import apps from "../redux/apps";
import users from "../redux/users";
import histories from "../redux/histories";
import auth from "../redux/auth";
import acl from "../redux/acl";

const rootReducer = combineReducers({
  i18nState,
  apps,
  users,
  auth,
  histories,
  acl,
});

export default rootReducer;
