import React from "react";
import { Route } from "react-router-dom";

class PublicRoute extends React.Component {
  render() {
    const { component: Component, ...rest } = this.props;
    return [
      <Route
        {...rest}
        key="route"
        render={(props) => {
          return <Component {...props} />;
        }}
      />,
    ];
  }
}

export default PublicRoute;
