import { all, fork } from "redux-saga/effects";
import authSaga from "./authSaga";
import appSaga from "./appSaga";
import userSaga from "./userSaga";
import historySaga from "./historySaga";
import aclSaga from "./aclSaga";

function* rootSagas() {
  yield all([
    fork(authSaga),
    fork(appSaga),
    fork(userSaga),
    fork(historySaga),
    fork(aclSaga),
  ]);
}

export default rootSagas;
