import * as types from "../portal.types";

const initState = {
  loading: false,
  error: false,
  code: undefined,
  data: {},
  updated: {
    loading: false,
    error: false,
    code: undefined,
    messages: "",
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initState, { type, payload }) {
  switch (type) {
    case types.GET_PROFILE_RQ:
      return {
        ...state,
        loading: true,
      };
    case types.GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        code: payload.code,
        data: payload.profile,
      };
    case types.GET_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        code: payload.code,
      };
    case types.GET_PROFILE_RF:
      return {
        ...state,
        loading: false,
        error: false,
        code: undefined,
      };

    case types.UPDATE_PROFILE_RQ:
      return {
        ...state,
        updated: {
          ...state.updated,
          loading: true,
        },
      };
    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        updated: {
          ...state.updated,
          loading: false,
          code: payload.code,
          messages: payload.messages,
        },
        data: payload.profile,
      };
    case types.UPDATE_PROFILE_FAIL:
      return {
        ...state,
        updated: {
          ...state.updated,
          loading: false,
          error: payload,
          code: payload.code,
          messages: payload.message,
        },
      };
    case types.UPDATE_PROFILE_RF:
      return {
        ...state,
        updated: {
          ...state.updated,
          loading: false,
          error: false,
          code: undefined,
        },
      };

    default:
      return state;
  }
}
