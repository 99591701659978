import { combineReducers } from "redux";
import signIn from "./signIn";
import userProfile from "./userProfile";

const auth = combineReducers({
  signIn,
  userProfile,
});

export default auth;
