module.exports = {
  API_KEY_DEFAULT: "MTBqMGRzb2tuNDhkNm93LWFwaS5hY2NvdW50LnBheW1l",
  portal: {
    url: "https://sbx-api-account.payme.net.vn",
    urlUpload: "https://sbx-static.payme.vn",
  },
  oauthConfig: {
    client_id:
      "696025184931-ujrb04aq683htdng4jg5p2417i9csnnl.apps.googleusercontent.com",
    redirect_uris: [
      "https://sbx-api-account.payme.net.vn/oauth2/google/callback",
    ],
  },
};
