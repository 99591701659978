/* eslint-disable import/no-anonymous-default-export */
import * as types from "../portal.types";

const initState = {
  loading: false,
  error: false,
  code: undefined,
  data: [],
  total: 0,
};

export default function (state = initState, { type, payload }) {
  switch (type) {
    case types.GET_HISTORIES_RQ:
      return {
        ...state,
        loading: true,
      };
    case types.GET_HISTORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        code: payload.code,
        data: payload.data,
        total: payload.total,
      };
    case types.GET_HISTORIES_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
        code: payload.code,
      };
    case types.GET_HISTORIES_RF:
      return {
        ...state,
        loading: false,
        error: false,
        code: undefined,
      };

    default:
      return state;
  }
}
