import { call, put, takeLatest } from "@redux-saga/core/effects";
import apiCaller from "api/lib/apiPortalCaller";
import { SUCCESS_CODE } from "configs/constants";
import * as types from "redux/portal.types";
import { customApiError } from "utils/helpers/customAPIError";

const { getHistoryList } = apiCaller;

function* getHistories({ payload }) {
  try {
    const { code, data } = yield call(getHistoryList, payload.data);

    if (!SUCCESS_CODE.includes(code)) {
      throw customApiError(code, data.message);
    }

    yield put({
      type: types.GET_HISTORIES_SUCCESS,
      payload: {
        code,
        data: data.items,
        total: data.total,
      },
    });
  } catch (error) {
    yield put({ type: types.GET_HISTORIES_FAIL, payload: error });
  } finally {
    yield put({ type: types.GET_HISTORIES_RF });
  }
}

export default function* watchAction() {
  yield takeLatest(types.GET_HISTORIES_RQ, getHistories);
}
