const ENV = process.env.REACT_APP_ENV || "development";

const configsByEnv = require(`../../env/${ENV}.config.js`);

const api = {
  portal: {
    ...configsByEnv.portal,
  },
};

const scopes = [
  "https://www.googleapis.com/auth/plus.me",
  "https://www.googleapis.com/auth/userinfo.email",
  "https://www.googleapis.com/auth/userinfo.profile",
];

const oauthConfig = {
  ...configsByEnv.oauthConfig,
  response_type: "code",
  access_type: "offline",
  flowName: "GeneralOAuthFlow",
};

export const configAPI = api;
export const SCOPES = scopes;
export const OAUTH_CONFIG = oauthConfig;
export const UPLOAD_URL = configsByEnv.portal.urlUpload;
