import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import AppRoutes from "./configs/routers";
import configureStore from "./configs/store.config";
import history from "./history";

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <AppRoutes />
      </Router>
    </Provider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));
