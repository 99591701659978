import { call, put, takeLatest } from "@redux-saga/core/effects";
import apiCaller from "api/lib/apiPortalCaller";
import { SUCCESS_CODE } from "configs/constants";
import * as types from "redux/portal.types";
import { customApiError } from "utils/helpers/customAPIError";

const { addUser, editUser, removeUser, getUserList } = apiCaller;

function* getUsers({ payload }) {
  try {
    const { code, data } = yield call(getUserList, payload.data);

    if (!SUCCESS_CODE.includes(code)) {
      throw customApiError(code, data.message);
    }

    yield put({
      type: types.GET_USERS_SUCCESS,
      payload: {
        code,
        data: data.items,
        total: data.total,
      },
    });
  } catch (error) {
    yield put({ type: types.GET_USERS_FAIL, payload: error });
  } finally {
    yield put({ type: types.GET_USERS_RF });
  }
}

function* createUser({ payload }) {
  try {
    const { code, data } = yield call(addUser, payload.data);
    if (!SUCCESS_CODE.includes(code)) {
      throw customApiError(code, data.message);
    }

    yield put({
      type: types.CREATE_USER_SUCCESS,
      payload: {
        code,
        messages: data.message,
      },
    });
  } catch (error) {
    yield put({
      type: types.CREATE_USER_FAIL,
      payload: error,
    });
  } finally {
    yield put({
      type: types.CREATE_USER_RF,
    });
  }
}

function* updateUser({ payload }) {
  try {
    const { code, data } = yield call(editUser, payload.data);

    if (!SUCCESS_CODE.includes(code)) {
      throw customApiError(code, data.message);
    }

    yield put({
      type: types.UPDATE_USER_SUCCESS,
      payload: {
        code,
        messages: data.message,
      },
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_USER_FAIL,
      payload: error,
    });
  } finally {
    yield put({
      type: types.UPDATE_USER_RF,
    });
  }
}

function* deleteUser({ payload }) {
  try {
    const { code, data } = yield call(removeUser, payload.data);

    if (!SUCCESS_CODE.includes(code)) {
      throw customApiError(code, data.message);
    }

    yield put({
      type: types.DELETE_USER_SUCCESS,
      payload: {
        code,
        messages: data.message,
      },
    });
  } catch (error) {
    yield put({
      type: types.DELETE_USER_FAIL,
      payload: error,
    });
  } finally {
    yield put({
      type: types.DELETE_USER_RF,
    });
  }
}

export default function* watchAction() {
  yield takeLatest(types.GET_USERS_RQ, getUsers);
  yield takeLatest(types.CREATE_USER_RQ, createUser);
  yield takeLatest(types.UPDATE_USER_RQ, updateUser);
  yield takeLatest(types.DELETE_USER_RQ, deleteUser);
}
