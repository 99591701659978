import {
  iconHistory,
  iconLogout,
  iconManageApp,
  iconManageUser,
  iconMenuApp,
  iconNoti,
  iconQLUD,
  iconUser,
} from "assets";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, Redirect } from "react-router-dom";
import { getAppsAction, getProfile, logoutAction } from "redux/portal.actions";
import Notification from "sweetalert2";

function HeaderFake() {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("token");

  const apps = useSelector((state) => state.apps.appList);

  const logout = useSelector((state) => state?.auth?.signIn);
  const profile = useSelector((state) => state?.auth?.userProfile.data);

  const [toggleApps, setToggleApps] = useState(false);
  const [toggleProfile, setToggleProfile] = useState(false);
  const appsRef = useRef(null);
  const profileRef = useRef(null);
  const myApp = apps?.data
    ?.filter((app) => app.isShow)
    ?.filter((app) => profile?.apps?.findIndex((item) => item.appId === app.appId) !== -1);

  useEffect(() => {
    function handleClickOutside(e) {
      if (appsRef.current && !appsRef.current.contains(e.target)) {
        setToggleApps(false);
      }
      if (profileRef.current && !profileRef.current.contains(e.target)) {
        setToggleProfile(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    dispatch(
      getAppsAction({
        accessToken,
        filter: {},
        paging: {
          start: 0,
          limit: 1000,
        },
        sort: {
          appname: -1,
        },
      })
    );
    dispatch(getProfile({ accessToken }));
  }, [accessToken, dispatch]);

  const handleLogout = () => {
    dispatch(logoutAction({ accessToken }));
  };

  useEffect(() => {
    if (logout.code) {
      if (logout.code !== 1000) {
        Notification.fire({
          icon: "error",
          title: "Thất bại",
          text: logout.error.message,
        });
      }
    }
  }, [logout]);

  if (!accessToken && !logout.loading) {
    return <Redirect to="/login" />;
  }

  return (
    <header className="header-master">
      <div className="header-master--wrapper">
        <Link to="/home" className="header-master__logo">
          <img src="/assets/images/logo-home.png" alt="" />
        </Link>
        <div className="header-master__panel">
          <div className="panel__notice">
            <img src={iconNoti} alt="" />
          </div>
          <div className="panel__apps" ref={appsRef} onClick={() => setToggleApps(!toggleApps)}>
            <img src={iconMenuApp} alt="" />
            <div className="apps-board" id="apps-board" style={{ display: toggleApps ? "flex" : "none" }}>
              {myApp.length > 0 ? (
                myApp?.map((item) => (
                  <div
                    className="board-item"
                    key={item.id}
                    onClick={() => {
                      window.open(`${item.urlRedirect}?token=${accessToken}`);
                    }}
                  >
                    <div className="board-item__icon">
                      <img src={item.icon} alt="" />
                    </div>
                    <p className="board-item__app-name">{item?.appname}</p>
                  </div>
                ))
              ) : (
                <h6 style={{ color: "red", marginBottom: "1.25rem" }}>Hiên không có ứng dụng nào</h6>
              )}
            </div>
          </div>
          <div className="panel__dropdown-profile" ref={profileRef} onClick={() => setToggleProfile(!toggleProfile)}>
            <div className="profile__box">
              <p>{profile?.fullname}</p>
              <div className="profile__box--image">
                <img src={profile?.avatar} alt="" />
              </div>
            </div>
            <div
              className="profile__dropdown"
              id="profile__dropdown"
              style={{ display: toggleProfile ? "block" : "none" }}
            >
              <NavLink to="/user/profile" className="dropdown__list profile-link">
                <img src={iconUser} alt="" />
                Hồ sơ của tôi
              </NavLink>
              <NavLink to="/home" className="dropdown__list apps-link">
                <img src={iconManageApp} alt="" />
                Danh sách ứng dụng
              </NavLink>
              <NavLink to="/apps" className="dropdown__list app-manager-link">
                <img src={iconQLUD} alt="" />
                Quản lý ứng dụng
              </NavLink>
              <NavLink to="/users" className="dropdown__list users-link">
                <img src={iconManageUser} alt="" />
                Quản lý người dùng
              </NavLink>
              <NavLink to="/history" className="dropdown__list history-link">
                <img src={iconHistory} alt="" />
                Lịch sử truy cập
              </NavLink>
              <div className="dropdown__list logout-link" onClick={handleLogout}>
                <img src={iconLogout} alt="" />
                Đăng xuất
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderFake;
