import { call, put, takeLatest } from "@redux-saga/core/effects";
import apiCaller from "api/lib/apiPortalCaller";
import { SUCCESS_CODE } from "configs/constants";
import * as types from "redux/portal.types";
import { customApiError } from "utils/helpers/customAPIError";

const { addApp, editApp, removeApp, getListApp } = apiCaller;

function* getApps({ payload }) {
  try {
    const { code, data } = yield call(getListApp, payload.data);

    if (!SUCCESS_CODE.includes(code)) {
      throw customApiError(code, data.message);
    }

    yield put({
      type: types.GET_APPS_SUCCESS,
      payload: {
        code,
        data: data.items,
        total: data.total,
      },
    });
  } catch (error) {
    yield put({ type: types.GET_APPS_FAIL, payload: error });
  } finally {
    yield put({ type: types.GET_APPS_RF });
  }
}

function* createApp({ payload }) {
  try {
    const { code, data } = yield call(addApp, payload.data);
    if (!SUCCESS_CODE.includes(code)) {
      throw customApiError(code, data.message);
    }

    yield put({
      type: types.CREATE_APP_SUCCESS,
      payload: {
        token: data.accessToken,
        code,
        messages: data.message,
      },
    });
  } catch (error) {
    yield put({
      type: types.CREATE_APP_FAIL,
      payload: error,
    });
  } finally {
    yield put({
      type: types.CREATE_APP_RF,
    });
  }
}

function* updateApp({ payload }) {
  try {
    const { code, data } = yield call(editApp, payload.data);

    if (!SUCCESS_CODE.includes(code)) {
      throw customApiError(code, data.message);
    }

    yield put({
      type: types.UPDATE_APP_SUCCESS,
      payload: {
        token: data.accessToken,
        code,
        messages: data.message,
      },
    });
  } catch (error) {
    yield put({
      type: types.UPDATE_APP_FAIL,
      payload: error,
    });
  } finally {
    yield put({
      type: types.UPDATE_APP_RF,
    });
  }
}

function* deleteApp({ payload }) {
  try {
    const { code, data } = yield call(removeApp, payload.data);

    if (!SUCCESS_CODE.includes(code)) {
      throw customApiError(code, data.message);
    }

    yield put({
      type: types.DELETE_APP_SUCCESS,
      payload: {
        token: data.accessToken,
        code,
        messages: data.message,
      },
    });
  } catch (error) {
    yield put({
      type: types.DELETE_APP_FAIL,
      payload: error,
    });
  } finally {
    yield put({
      type: types.DELETE_APP_RF,
    });
  }
}

export default function* watchAction() {
  yield takeLatest(types.GET_APPS_RQ, getApps);
  yield takeLatest(types.CREATE_APP_RQ, createApp);
  yield takeLatest(types.UPDATE_APP_RQ, updateApp);
  yield takeLatest(types.DELETE_APP_RQ, deleteApp);
}
