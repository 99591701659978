module.exports = {
  API_KEY_DEFAULT: "YXU2b2hzMGtrY2FkZGs0LWFwaS5hY2NvdW50LnBheW1l",
  portal: {
    url: "https://api-account.payme.net.vn",
    urlUpload: "https://static.payme.vn",
  },
  oauthConfig: {
    client_id:
      "696025184931-nt0c5efe76gjnq1eklqv0tfv1uihf7e3.apps.googleusercontent.com",
    redirect_uris: ["https://api-account.payme.net.vn/oauth2/google/callback"],
  },
};
