import Loading from "components/common/Loading";
import _ from "lodash";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, withRouter } from "react-router-dom";
import routes from "../routers";
import PrivateLayout from "./private.router";
import PublicLayout from "./public.router";

function AppRoutes(props) {
  return (
    <Router>
      <Suspense fallback={<Loading loading />}>
        <Switch>
          {_.map(routes, (routeItem, key) => {
            const { component, isPrivate, ...rest } = routeItem;

            return (
              <routeItem.layout
                key={key}
                component={component}
                {...rest}
                {...props}
                isPrivate={isPrivate}
              >
                {!isPrivate ? (
                  <PublicLayout
                    component={component}
                    route={routeItem}
                    {...rest}
                    {...props}
                  />
                ) : (
                  <PrivateLayout
                    component={component}
                    route={routeItem}
                    {...rest}
                    {...props}
                  />
                )}
              </routeItem.layout>
            );
          })}
        </Switch>
      </Suspense>
    </Router>
  );
}

export default withRouter(AppRoutes);
