import React from "react";
import HashLoader from "react-spinners/HashLoader";

function Loading({ loading = false, size = 50 }) {
  return (
    <div
      className="master-loading"
      style={{
        position: "fixed",
        background: "#54545487",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: loading ? "block" : "none",
        zIndex: 9999,
      }}
    >
      <HashLoader
        color="#36D7B7"
        loading={loading}
        size={size}
        css={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </div>
  );
}

export default Loading;
