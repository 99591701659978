const ENV = process.env.REACT_APP_ENV || "development";

const configsByEnv = require(`../../env/${ENV}.config.js`);

export const API_KEY_DEFAULT = configsByEnv.API_KEY_DEFAULT;
export const SUCCESS_CODE = [1000, 1003];
export const ACTIONS = [
  "Đăng nhập",
  "Đăng xuất",
  "Cập nhật tài khoản",
  "Đổi mật khẩu",
  "Tạo tài khoản",
  "Cập nhật phòng ban/account type",
  "Xoá tài khoản",
  "User quên mật khẩu",
  "User reset mật khẩu cho TH quên mật khẩu",
  "Hủy liên kết tài khoản google",
  "Liên kết tài khoản google",
  "Liên kết tài khoản telegram",
  "Hủy liên kết tài khoản telegram",
  "Tạo mới ứng dụng",
  "Cập nhật ứng dụng",
  "Xóa ứng dụng",
  "Cấp quyền truy cập ứng dụng",
  "Xóa quyền truy cập ứng dụng",
];
