// sign in by MINH
export const SIGNIN_RQ = "SIGNIN_RQ";
export const SIGNIN_FAIL = "SIGNIN_FAIL";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_RF = "SIGNIN_RF";

export const CHECK_AUTHORIZE_RQ = "CHECK_AUTHORIZE_RQ";
export const LOGOUT_RQ = "LOGOUT_RQ";
export const LOGOUT_SUCCESS_ = "LOGOUT_SUCCESS_";
export const LOGOUT_FAIL_ = "LOGOUT_FAIL_";

export const GET_PROFILE_RQ = "GET_PROFILE_RQ";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_RF = "GET_PROFILE_RF";

export const UPDATE_PROFILE_RQ = "UPDATE_PROFILE_RQ";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_RF = "UPDATE_PROFILE_RF";

// APP ACTION
export const GET_APPS_RQ = "GET_APPS_RQ";
export const GET_APPS_FAIL = "GET_APPS_FAIL";
export const GET_APPS_SUCCESS = "GET_APPS_SUCCESS";
export const GET_APPS_RF = "GET_APPS_RF";

export const CREATE_APP_RQ = "CREATE_APP_RQ";
export const CREATE_APP_FAIL = "CREATE_APP_FAIL";
export const CREATE_APP_SUCCESS = "CREATE_APP_SUCCESS";
export const CREATE_APP_RF = "CREATE_APP_RF";

export const UPDATE_APP_RQ = "UPDATE_APP_RQ";
export const UPDATE_APP_FAIL = "UPDATE_APP_FAIL";
export const UPDATE_APP_SUCCESS = "UPDATE_APP_SUCCESS";
export const UPDATE_APP_RF = "UPDATE_APP_RF";

export const DELETE_APP_RQ = "DELETE_APP_RQ";
export const DELETE_APP_FAIL = "DELETE_APP_FAIL";
export const DELETE_APP_SUCCESS = "DELETE_APP_SUCCESS";
export const DELETE_APP_RF = "DELETE_APP_RF";

// USER ACTION
export const GET_USERS_RQ = "GET_USERS_RQ";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_RF = "GET_USERS_RF";

export const CREATE_USER_RQ = "CREATE_USER_RQ";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_RF = "CREATE_USER_RF";

export const UPDATE_USER_RQ = "UPDATE_USER_RQ";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_RF = "UPDATE_USER_RF";

export const DELETE_USER_RQ = "DELETE_USER_RQ";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_RF = "DELETE_USER_RF";

// HISTORIES ACTION
export const GET_HISTORIES_RQ = "GET_HISTORIES_RQ";
export const GET_HISTORIES_FAIL = "GET_HISTORIES_FAIL";
export const GET_HISTORIES_SUCCESS = "GET_HISTORIES_SUCCESS";
export const GET_HISTORIES_RF = "GET_HISTORIES_RF";

// ACL
export const GET_ACL_RQ = "GET_ACL_RQ";
export const GET_ACL_FAIL = "GET_ACL_FAIL";
export const GET_ACL_SUCCESS = "GET_ACL_SUCCESS";
export const GET_ACL_RF = "GET_ACL_RF";

export const SETUP_ACL_RQ = "SETUP_ACL_RQ";
export const SETUP_ACL_FAIL = "SETUP_ACL_FAIL";
export const SETUP_ACL_SUCCESS = "SETUP_ACL_SUCCESS";
export const SETUP_ACL_RF = "SETUP_ACL_RF";

export const REMOVE_ACL_RQ = "REMOVE_ACL_RQ";
export const REMOVE_ACL_FAIL = "REMOVE_ACL_FAIL";
export const REMOVE_ACL_SUCCESS = "REMOVE_ACL_SUCCESS";
export const REMOVE_ACL_RF = "REMOVE_ACL_RF";
