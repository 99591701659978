import { combineReducers } from "redux";
import appList from "./appList";
import created from "./appCreated";
import updated from "./appUpdated";
import deleted from "./appDeleted";

const appReducer = combineReducers({
  appList,
  created,
  updated,
  deleted,
});
export default appReducer;
